import { message } from '@components/Message';
import PopConfirm from '@components/PopConfirm';
import IconBrandCards from '@icons/IconBrandCards';
import { paymentService } from '@services/paymentService';
import { getPrevDigitsCard } from '@utils/common';
import React, { memo, useCallback, useState } from 'react';

const CardItem = memo(({ reload, ...props }) => {
   const [submitting, setSubmitting] = useState(false);

   const handleRemove = useCallback(async () => {
      message.loading('Deleting...', 0);
      await setSubmitting(true);
      const responseData = await paymentService.deleteCard({ uids: [props?.uid] });
      if (responseData.isSuccess) {
         reload && (await reload());
         message.success('Deleted card.');
      } else {
         message.error(responseData.message);
      }
      setSubmitting(false);
   }, [props?.uid, reload]);

   const setAsDefault = useCallback(async () => {
      message.loading('Updating...', 0);
      await setSubmitting(true);
      const responseData = await paymentService.setDefaultCard(props?.uid);
      if (responseData.isSuccess) {
         reload && (await reload());
         message.success('Success.');
      } else {
         message.error(responseData.message);
      }
      setSubmitting(false);
   }, [props?.uid, reload]);

   return (
      <div className="profile-info__bank__item">
         <div className="profile-info__bank__item--info">
            <IconBrandCards brand={props?.brand} />
            <label>
               {getPrevDigitsCard(props?.brand)}
               {props.last4}
            </label>
            {props?.default && <span>Default</span>}
         </div>
         <div className="profile-info__bank__item--actions">
            <PopConfirm
               zIndex={4039}
               title="Are you sure to delete this card?"
               placement="bottom"
               onConfirm={handleRemove}
               disabled={submitting}
            >
               <button className="action--remove" disabled={submitting}>
                  Delete
               </button>
            </PopConfirm>
            <button
               className="action--active"
               disabled={props?.default || submitting}
               onClick={setAsDefault}
            >
               Set as default
            </button>
         </div>
      </div>
   );
});

export default CardItem;
